import styles from '../../Setup.module.css'
import styles1 from '../../cssmodules/signon.module.css';
import {gql, useLazyQuery, useQuery} from '@apollo/client'
import {useState} from 'react'
import Select from 'react-select'
import * as _storage from "../../service/storage";
var QRCode = require('qrcode.react');

const BRAND_QUERY= gql`
    query
    {
        brands
        {
            id,
            name
        }
    }
`;

const DEVICE_QUERY = gql`
    query CheckDevice($code: String!)
    {
        devices(where:{identifier:$code})
        {
            id,
            name,
            site{
                id,
                name,
                deliverytime,
                collectiontime
            },
            type
        }
    }
`;

const newCode = makeid(10);

//const SITE_QUERY = gql``;

function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
}

var showPinScreen = false;
function Setup()
{
    const {loading, error, data} = useQuery(BRAND_QUERY);

    const [showEposOption, setShowEposOption] = useState(false);

    const [currentCode, setCurrentCode] = useState("");

    const [step,setStep] = useState(1);
    const [config,setConfig] = useState({allowedAccess:false,mode:undefined,brands:[],multiBrand: false});
    const [useExistingCode,setUseExistingCode] = useState(false);
    const [existingCode,setExistingCode] = useState("");

    const [checkDevice, { loading:loadingDevice, data:deviceData }] = useLazyQuery(DEVICE_QUERY, {
        variables: {code:(useExistingCode ? existingCode : newCode)},
        onCompleted:(data) => {
            console.log(data.devices);
        }
    })

    if(loading){return 'Loading Setup..';}
    if(error){return 'Something went wrong while loading setup..';}

    if(loadingDevice)
    {
        return ("Checking Device..");
    }

    if(deviceData && config.allowedAccess === false && deviceData.devices.length === 1)
    {
        var newConfig = {...config};

        newConfig.deviceConfig = deviceData.devices[0];
        newConfig.allowedAccess = true;
        setConfig(newConfig);
       
    }

    function setConfigVar(event)
    {

        var newConfig = {...config}

        var targetprop = event.target.dataset.prop;
        
        var val = event.target.value;
        // console.log("brand event:" , event.target[event.target.selectedIndex].getAttribute('data-name'));

        if(targetprop === "brands")
        {
            val = [];
            for(let x=0;x<event.target.selectedOptions.length;x++)
            {
                val.push(event.target.selectedOptions[x].value);
            }
            
            newConfig.multiBrand = (val.length > 1);
        }
        
        newConfig[targetprop] = val;
        newConfig["CurrentBrandName"] = event.target[event.target.selectedIndex].getAttribute('data-name');
        setConfig(newConfig);
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    function completeSetup()
    {
        _storage.setInitialConfig(config);
        window.location.reload();
    }

    function keyPress(key)
    {
        var val = key.target.dataset.value;
        var newVal = (val === "-" ? currentCode.substring(0, currentCode.length - 1) : currentCode + val);
        setCurrentCode(newVal);
        key.target.style.backgroundColor = "#828181";
        setTimeout(function() {
            key.target.style.backgroundColor = "white";
        }, 40);
    }

    function matchCode(){
        var date = new Date();
        currentCode === existingCode+date.getMinutes() ? setStep(4) : setStep(2);
    }

    function renderStep()
    {
        console.log(config);
        console.log(step);
        console.log(existingCode);
        var retStep;
        switch(step)
        {
            case 1 :
                retStep = (
                    <div className={styles.step}>
                        <div className={styles.formgroup}>
                            <label className={styles.formgroup_label}>Allow Device Access</label>
                            <div style={{margin:"1em 0"}}>
                                <div className={config.allowedAccess ? styles.deviceAccessIcon : styles.deviceAccessIconDenied}>
                                    <i className={config.allowedAccess ? "icon-tick" : "icon-minus"}></i>
                                </div>
                                <div className={styles.accessText}>{config.allowedAccess ? "Access Granted" : "Access Denied"}</div>
                            </div>

                                <div className="methodSelector">
                                    <label>Use Existing Code?</label>
                                    <label className="switch">
                                        <input type="checkbox" checked={useExistingCode} onChange={(e)=>{setUseExistingCode(e.target.checked)}}/>
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                {
                                    useExistingCode === true ? 
                                    (
                                        <div style={{marginBottom:".5em"}}>
                                            <label>Enter your code:</label>
                                            <input type="text" value={existingCode} onChange={(e)=>{setExistingCode(e.target.value)}}/>
                                        </div>
                                    )
                                    :
                                    (
                                        <div style={{marginBottom:".5em"}}>
                                            <div style={{marginBottom:".5em"}}>Your Code:</div>
                                            <QRCode value={newCode} />,
                                        </div>
                                    )
                                }
                            
                            <button className="btn btn-info" onClick={checkDevice}>Check Again</button>
                        </div>
                        {
                            config.allowedAccess ? (
                                <div className="nav buttonBar">
                                    <button className="btn btn-danger" disabled>Back</button>
                                    <button className="btn btn-success" onClick={()=>{setStep(2)}}>Next</button>
                                </div>
                            )
                            : null
                        }     
                    </div>
                );
            break;
            case 2 :
                showPinScreen = true;
                retStep = (
                    <div className={styles.step}>
                        <div className={styles.formgroup}>
                            <label className={styles.formgroup_label}>Device Mode</label>
                            <select className={styles.formcontrol_select} data-prop="mode" onChange={setConfigVar} value={config.mode}>
                                {/*<option value="EPOS">EPOS</option>*/}
                                <option value="SCREEN">Screen</option>
                            </select>
                        </div>
                        <div className="nav buttonBar">
                            <button className="btn btn-danger" onClick={()=>{setStep(1)}}>Back</button>
                            <button className="btn btn-success" onClick={()=>{setStep(3)}}>Next</button>
                        </div>
                    </div>
                );
            break;
            case 4 :
                retStep = (
                    <div className={styles.step}>
                        <div className={styles.formgroup}>
                            <label className={styles.formgroup_label}>Device Mode</label>
                            <select className={styles.formcontrol_select} data-prop="mode" onChange={setConfigVar} value={config.mode}>
                                <option value="EPOS">EPOS</option>
                                <option value="SCREEN">Screen</option>
                            </select>
                        </div>
                        <div className="nav buttonBar">
                            <button className="btn btn-danger" onClick={()=>{setStep(1)}}>Back</button>
                            <button className="btn btn-success" onClick={()=>{setStep(3)}}>Next</button>
                        </div>
                    </div>
                );
            break;
            case 5 :
                retStep = (
                    <div className={styles1.step}>
                        <div id="SignOn" className={styles1.SignOn}>
                            <h4 style={{color : 'white'}}>Enter Your Pin</h4>
                            <div className={styles1.SignOnCode}>
                                <input type="password" className={styles1.code} value={currentCode} style={{color : 'white'}} readOnly/>
                            </div>
                            <div className={styles1.keypadContainer}>
                                <div className={styles1.keyRow}>
                                    <div onClick={keyPress} className={styles1.key} data-value="1" style={{backgroundColor : 'white'}}>1</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="2" style={{backgroundColor : 'white'}}>2</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="3" style={{backgroundColor : 'white'}}>3</div>
                                </div>
                                <div className={styles1.keyRow}>
                                    <div onClick={keyPress} className={styles1.key} data-value="4" style={{backgroundColor : 'white'}}>4</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="5" style={{backgroundColor : 'white'}}>5</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="6" style={{backgroundColor : 'white'}}>6</div>
                                </div>
                                <div className={styles1.keyRow}>
                                    <div onClick={keyPress} className={styles1.key} data-value="7" style={{backgroundColor : 'white'}}>7</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="8" style={{backgroundColor : 'white'}}>8</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="9" style={{backgroundColor : 'white'}}>9</div>
                                </div>
                                <div className={styles1.keyRow}>
                                    <div onClick={keyPress} className={styles1.key} data-value="-" style={{backgroundColor : 'white'}}>C</div>
                                    <div onClick={keyPress} className={styles1.key} data-value="0" style={{backgroundColor : 'white'}}>0</div>
                                    <div className={styles1.key} style={{backgroundColor : 'white'}} onClick={matchCode}>G</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            break;
            case 3 :
                retStep = (
                    <div className={styles.step}>
                        <div className={styles.formgroup}>
                            <label className={styles.formgroup_label}>Select Brands</label>
                            <select className={styles.formcontrol_select} data-prop="brands" onChange={setConfigVar} value={config.brands} multiple>
                                {
                                    data.brands.map((brand)=>(
                                        <option key={brand.id} value={brand.id} data-name={brand.name}>{brand.name}</option>
                                    ))
                                }
                            </select>



                            {/*<Select options={options} style={{color : 'white'}} />*/}
                        </div>
                        <div className="nav buttonBar">
                            <button className="btn btn-danger" onClick={()=>{setStep(2)}}>Back</button>
                            <button className="btn btn-success" onClick={completeSetup}>Finish</button>
                        </div>
                    </div>
                );
            break;
            default:
                setStep(1);
            break;
        }

        return retStep;
    }

    return (
        <>
            <div className={styles.Setup}>
                <div className="setupContainer">
                    <h2 className="brandLogo" style={{margin:0}}>Clear EPOS</h2>
                    <h4 className={styles.subtitle}>Application Setup</h4>
                    {
                        renderStep()
                    }
                    <style>
                        {"html, body, #root{margin:0;height:100%;}"}
                    </style>
                </div>
            </div>
            {
                showPinScreen ?
                    (
                        <div id="DevOptions" className={"show-" + showEposOption} onClick={()=>{setStep(5)}}></div>
                    )
                    :
                    null
            }

        </>
    );
}

export default Setup;