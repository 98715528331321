import * as _storage from "../../../service/storage";
import styles from "../../../Setup.module.css";

import {useState} from 'react';
import {useQuery, useMutation , gql} from '@apollo/client';

const UPDATE_SITE_DELIVERY_TIME = gql`
    mutation UpdateSiteDeliveryTime(
        $deliverytime: Int,
        $collectiontime : Int,
        $siteId: ID!
    )
    {
        updateSite(input:{where:{id:$siteId},data:{
            deliverytime: $deliverytime,
            collectiontime : $collectiontime
        }})
        {
            site{
                id,
                name,
                deliverytime,
                collectiontime
            }
        }
    }
`;

function UserSettings(props)
{
    var devices_data = JSON.parse(localStorage.getItem('deviceCache'));
    var activePrinter = JSON.parse(localStorage.getItem('settingscache')).activePrinter;

    const [deliveryTime , setDeliveryTime] = useState(props.siteData.deliverytime);
    const [collectionTime , setCollectionTime] = useState(props.siteData.collectiontime);
    const [defaultPrinter , setDefaultPrinter] = useState(activePrinter);

    const [updateSiteDeliveryTime] = useMutation(UPDATE_SITE_DELIVERY_TIME, {
        onCompleted:(data) => {
            props.changeTillSettings(data.updateSite.site);
        }
    });


    function changeprintingdevice(event){
        setDefaultPrinter(event.target.value);
    }

    function changeDeliveryTime(event){
        setDeliveryTime(event.target.value);
    }

    function changeCollectionTime(event){
        setCollectionTime(event.target.value);
    }

    function changeTillSettings (){
        localStorage.setItem('settingscache',JSON.stringify({activePrinter : defaultPrinter}));
        var data = updateSiteDeliveryTime({variables: {deliverytime : parseInt(deliveryTime) , collectiontime : parseInt(collectionTime) , siteId : props.siteData.id}});
    }

    return (
        <div>
            <center><h4>Till Settings</h4></center>
            <div className="">

                <div className="f_left" style={{width : '34%'}}>
                    <label>Till Printer</label>
                    <select className={styles.formcontrol_select} onChange={changeprintingdevice} defaultValue={activePrinter}>
                        {
                            devices_data.map((device) => {
                                if(device.type === 'printer'){
                                    return (
                                        <option value={device.id} key={device.id}>{device.name}</option>
                                    );
                                }
                            })
                        }
                    </select>
                </div>
                <div className="f_right" style={{width : '66%'}}>
                    <div className="f_left" style={{width : '49%'}}>
                        <label>Delivery Time</label>
                        <input type="number" className={styles.formcontrol_select} defaultValue={deliveryTime} onChange={changeDeliveryTime} />
                    </div>
                    <div className="f_right" style={{width : '49%'}}>
                        <label>Collection Time</label>
                        <input type="number" className={styles.formcontrol_select} defaultValue={collectionTime} onChange={changeCollectionTime}/>
                    </div>
                </div>
                <br/>
                <button className="btn btn-success f_right" style={{marginTop : 10}} onClick={changeTillSettings}>Update Settings</button>
            </div>
        </div>
    );

}

export default UserSettings;