import {useUserUpdate} from '../../../contexts/UserContext';

import DateTimeClock from "../ui/DateTimeClock";
import React, { useState } from 'react';

import {LoadingData} from '../../../components/till/ui/DisplayUtility';
import { useMutation , useQuery, gql } from '@apollo/client';

import ManageOrders from "./ManageOrders";
import UserSettings from "./UserSettings";

//Import Tabs
import Tabs from '../controls/Tabs';
import NewOrdersTab from '../controls/tabs/NewOrdersTab';
import CompleteOrdersTab from '../controls/tabs/CompleteOrdersTab';
import InHouseOrdersTab from '../controls/tabs/InHouseOrdersTab';
import ProcessingOrdersTab from '../controls/tabs/ProcessingOrdersTab';
import {ORDER_QUERY, UPDATE_ORDER_STATUS} from "../../../service/queries";

const DEVICE_SITE_QUERY = gql`
    query SiteData($device_id: ID!)
        {device(id:$device_id)
        {
            id,
            site
            {
                id,
                name,
                deliverytime,
                collectiontime
            }
        }
    }
`;

const OPEN_ORDERS = gql`
    query {orders(sort: "created_at:desc") {
        id,
        ordernumber,
        type,
        status,
        paymentstatus,
        staff_member
        {
            id,
            firstname,
            lastname
        }
        customer{
            firstname,
            lastname
        }
        address{
            id,
            postalcode
        }
        created_at
    }}
`;
function ManageOrdersPanel()
{

    let setCurrentUser = useUserUpdate();
    let [renderUsersSettings, setrenderUsersSettings] = useState(false);

    //Fetch all orders
    const { loading, error, data } = useQuery(OPEN_ORDERS,{fetchPolicy: "cache-and-network"});

    const [siteData , setSiteData] = useState("");

    const {siteloading, siteerror} = useQuery(DEVICE_SITE_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables:{device_id: JSON.parse(localStorage.getItem("appConfig")).deviceConfig.id},
        onCompleted:(data) => {
            setSiteData(data.device.site);
        }
    });

    if (loading) return LoadingData();
    if (error) return <p>Error :(</p>;

    function updateTillSettings(data){
        setSiteData(data);
        setrenderUsersSettings(!renderUsersSettings);
    }

    function changeUserSettings (){
        setrenderUsersSettings(!renderUsersSettings);
    }

    return (

        <section id="ManageOrders" className="panel sixty" style={{overflow : "auto"}}>
            <header>
                <div className="left">
                    <div className="brandLogoPlaceholder">{JSON.parse(localStorage.getItem("appConfig")).CurrentBrandName}</div>
                    <DateTimeClock/>
                    <div className="loggedUser">  | James</div>
                </div>
                <div className="right">
                    <div style={{marginRight : 10}} onClick={() => {changeUserSettings()}} className="btn btn-icon btn-info "><i className="icon-settings"></i></div>
                    <div onClick={() => {setCurrentUser(null)}} className="btn btn-icon btn-danger "><i className="icon-exit"></i></div>
                </div>
            </header>
            <div className="clearfix"></div>
            {
                renderUsersSettings
                ?
                <UserSettings changeTillSettings={updateTillSettings} siteData={siteData}/>
                :
                <ManageOrders ordersData ={data} />
            }

        </section>

    );
}

export default ManageOrdersPanel;